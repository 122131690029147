import {
  PostCard,
  PostsByCategory,
  useSitePosts,
} from '@entities/post';
import { t } from '@lib/i18n';
import Typography from '@ui/Typography';
import { FeatureLayout, Layout } from '@widgets/layout';
import { VideoSection } from './ui/VideoSection';
import { LeadMagnitBanner } from './ui/LeadMagnitBanner';

const FeatureContent = ({ post }) => (
  <div className="grid-layout mx-auto flex flex-col items-start pb-8 lg-down:items-center lg-down:pb-2">
    <Typography variant="header1" className="lg-down:text-center">
      {t('blog.header')}
    </Typography>
    <Typography variant="subhead1" className="mt-4 lg-down:text-center">
      {t('blog.subheader')}
    </Typography>
    <PostCard
      post={post}
      isGeneral
      className="lg:mt-8 lg-down:mt-7"
    />
  </div>
);

export const BlogV2 = () => {
  const groupedPosts = useSitePosts();
  
  return (
    <FeatureLayout 
      mainLayoutProps={{ seo: t('seo.blog'), banner: true }}
      featureChildren={<FeatureContent post={groupedPosts.general[0] || groupedPosts.all[0]} />}
      className="!pb-0"
    >
      <div className="grid-layout mx-auto lg:py-8 lg-down:pt-5 lg-down:pb-7">
        <PostsByCategory posts={groupedPosts} />
      </div>
      <LeadMagnitBanner />
      <VideoSection />
    </FeatureLayout>
  );
};
